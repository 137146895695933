<template>
	<div id="app">
		<div class="input-container">
			<input type="password" v-model="accessKey" placeholder="Access-Key">
			<textarea v-model="inputText" rows="10" placeholder="Clutch Case
Horizon Case
..."></textarea>
		</div>
		<div class="convert-container">
			<div class="divider"></div>
			<div class="button-and-progress">
				<div class="request-active" v-if="requestActive">
					Working...
				</div>
				<button @click="getPrices" :disabled="inputItems.length <= 0" v-else>Get Prices</button>
			</div>
		</div>
		<div class="output-container">
			<div class="rate-limit-error" v-if="outputPrices.length >= 1 && encounteredError">
				<div class="exclamation">!</div>
				<div class="notice">The prices are due to API-Rate-Limits incomplete.<br>Try again in some minutes.
				</div>
				<div class="exclamation">!</div>
			</div>
			<button @click="copyToClipboard" :disabled="outputPrices.length <= 0">Copy prices to clipboard</button>
		</div>
	</div>
</template>

<script>
	import copy from 'copy-to-clipboard';

	export default {
		name: 'App',
		data: () => ({
			inputText: '',
			outputPrices: [],
			requestActive: false,
			accessKey: ''
		}),
		computed: {
			inputItems() {
				if (this.inputText.length >= 1) {
					const items = this.inputText.trim().split('\n');

					return items.map((item) => {
						return item.trim();
					});
				}
				else {
					return [];
				}
			},
			encounteredError() {
				return this.outputPrices.includes(null);
			}
		},
		methods: {
			copyToClipboard() {
				const prices = this.outputPrices;
				if (prices.length >= 1) {
					copy(prices.join('\r\n'));
				}
				else {
					alert('nothing to copy...');
				}
			},
			getPrices() {
				const items = this.inputItems;
				this.requestActive = true;
				this.$http({
					method: 'POST',
					url: `${ process.env.VUE_APP_API_PREFIX }/getPrices/${ this.accessKey }`,
					data: items
				}).then((response) => {
					this.outputPrices = response.data.prices;
					this.requestActive = false;
				}).catch((error) => {
					if (error.response.status === 401) {
						alert('Access-Key is wrong.');
						this.accessKey = '';
					}

					this.requestActive = false;
				});
			}
		},
		watch: {
			'accessKey'(newValue){
				localStorage.setItem('access-key', newValue);
			}
		},
		mounted() {
			const key = localStorage.getItem('access-key');
			if (key) {
				this.accessKey = key;
			}
		},
		metaInfo: {
			title: 'Steam Item Price Thing'
		}
	};
</script>

<style lang="scss">
	body, html {
		float: left;
		height: 100vh;
		width: 100vw;

		display: flex;
		align-items: flex-start;
		justify-content: center;

		font-family: sans-serif;
	}

	#app {
		max-width: 600px;
		width: 600px;
		margin-top: 20vh;

		button {
			background: #00a5b6;
			font-size: 1.2rem;
			color: white;
			//font-weight: bold;
			border-radius: 5px;
			padding: 0.5rem 1.5rem;
			border: none;
			box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
			cursor: pointer;

			&:disabled {
				box-shadow: none;
				opacity: 0.3;
				cursor: not-allowed;
			}

			&:not(:disabled):hover {
				filter: brightness(110%);
				box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
			}
		}

		textarea, input {
			border: 2px solid #dcdcdc;
			width: 100%;
			border-radius: 10px;
			padding: 0.5rem 1rem;
			font-size: 1rem;
			outline: none;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
			font-family: sans-serif;
			transition: border-color 0.2s cubic-bezier(0, 0.5, 0.5, 1);

			&:hover {
				border-color: #c1c1c1;
			}
		}

		.input-container {
			input {
				margin-bottom: 1rem;
			}
		}

		.convert-container {
			margin: 3rem 0;
			position: relative;
			height: 4rem;
			display: flex;
			align-items: center;
			justify-content: center;

			.divider {
				background: #dcdcdc;
				height: 2px;
				width: 100%;
				position: absolute;
				top: calc(2rem - 1px);
				left: 0;
				z-index: 0;
			}

			.button-and-progress {
				background: white;
				z-index: 1;
				padding: 0 1rem;

			}
		}

		.output-container {
			margin: 3rem 0;
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.rate-limit-error {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				text-align: center;
				margin-bottom: 3rem;
				padding: 1rem;
				background: #ff00000d;
				border-radius: 10px;
				box-shadow: 0 0 0px 4px #ff00001f;
				border: 2px solid #ff9393;

				.exclamation {
					width: 4rem;
					font-size: 3rem;
					line-height: 3rem;
					color: #ff9393;
					font-weight: bolder;
				}

				.notice {
					flex: 1;
					padding: 0 1rem;
				}
			}
		}
	}
</style>
