import Vue from 'vue';
import App from './App.vue';
import store from './store';

import 'sanitize.css';
import axios from 'axios';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

Vue.use(VueMeta);

Vue.http = Vue.prototype.$http = axios;

new Vue({
	store,
	render: h => h(App)
}).$mount('#app');
